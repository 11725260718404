import { getSystemSetting as apiGetSystemSetting } from '../api/SystemSettings/GetSystemSetting'

export default {
    state: {
        systemSettings: []
    },
    getters: {
        GetSystemSettingStore: state => type => {
            return state.systemSettings[type]
        }
    },
    mutations: {
        SetSystemSetting: (state, payload) => {
            if(state.systemSettings[payload.type] === null || state.systemSettings[payload.type] <= 0){
                state.systemSettings[payload.type] = payload.data
            }else{
                state.systemSettings[payload.type] = payload.data
            }
        }
    },
    actions: {
        GetSystemSetting: (context, payload) => {
            return apiGetSystemSetting(payload.type)
                .then(response => {
                    if (response.status === 200) {
                        context.commit('SetSystemSetting', { type: payload.type, data: response.data.setting })
                        return response.data
                    }
                    return null
                })
        }
    }
}