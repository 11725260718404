
import { getUserCvrNumber as apiGetUserCvrNumber } from '@/api/CustomerDetails/GetUserCvrNumber'
import { getUserPNumberList as apiGetUserPNumberList } from '@/api/CustomerDetails/GetUserPNumberList'

import { getUserDetails as apiGetUserDetails } from '@/api/CustomerDetails/GetUserDetails'
import { updateCustomerEanNumberAndEmail as apiUpdateCustomerEanNumberAndEmail } from '@/api/CustomerDetails/UpdateCustomerEanNumberAndEmail'
import { createUser as apiCreateUser } from '@/api/CreateUser'
import { getCvrPNumberList as apiGetCvrPNumberList } from '@/api/GetCvrPNumberList'
import { validateCvrNumber as apiValidateCvrNumber } from '@/api/ValidateCvrNumber'

import { changeUserPassword as apiChangeUserPassword } from '@/api/ChangeUserPassword'
import { requestPasswordReset as apiRequestPasswordReset} from '@/api/RequestPasswordReset'
import { confirmPasswordReset as apiConfirmPasswordReset } from '@/api/ConfirmPasswordReset'
import { confirmEmail as apiConfirmEmail } from '@/api/ConfirmEmail'
import { getUserEmailConfirmationToken as apiGetUserEmailConfirmationToken } from '@/api/GetUserEmailConfirmationToken'
import { logIn as apiLogIn } from '@/api/LogIn'
import { logInCriipto as apiLogInCriipto } from '@/api/LogInCriipto'
import { logInCriiptoPrivate as apiLogInCriiptoPrivate} from '@/api/LogInCriiptoPrivate'
import { refreshToken as apiRefreshToken } from '@/api/RefreshToken'

import { getCustomerPrivateByCriiptoIdentification as apiGetCustomerPrivateByCriiptoIdentification } from '@/api/GetCustomerPrivateByCriiptoIdentification'
import { updatePrivateUserDetails as apiUpdatePrivateUserDetails } from '@/api/CustomerDetails/CustomerPrivate/UpdatePrivateUserDetails'

import { createValidateUserEmailCode as apiCreateValidateUserEmailCode } from '../api/CustomerDetails/CustomerPrivate/ValidateUser/CreateValidateUserEmailCode'
import {validateUserEmailCode as apiValidateUserEmailCode} from '../api/CustomerDetails/CustomerPrivate/ValidateUser/ValidateUserEmailCode'

import {getOrderPdf as apiGetOrderPdf} from '@/api/Order/GetOrderPdf'
import {getCustomerOrders as apiGetCustomerOrders} from '@/api/Order/GetCustomerOrders'
export default {
    state: {
        //Refresh token
        intervalContainer: null,
        //Check if email is confirmed
        emailConfirmed: false,
        //The selected cvr number
        selectedCvrNumber: null,
        //The selected p number
        selectedPNumber: null,
        selectedPNumberName: null,
        selectedCustomerName: null,

        selectedCustomerEmail: null

    },
    mutations: {
        setIntervalContainer: (state, container) => {
            state.intervalContainer = container
        },
        clearIntervalContainer: state => {
            clearInterval(state.intervalContainer)
        },
        setEmailConfirmed: (state, container) => {
            state.emailConfirmed = container.emailConfirmed
        },

        setSelectedCvrNumber: (state, cvrNumber) => {
            state.selectedCvrNumber = cvrNumber
        },
        setSelectedPNumber: (state, pNumber) => {
            state.selectedPNumber = pNumber
        },
        setSelectedPNumberName: (state, pNumberName) => {
            state.selectedPNumberName = pNumberName
        },
        setSelectedPrivateCustomer: (state, payload) => {
            if(payload === null){
                state.selectedCustomerName = null
                localStorage.setItem('customerEmail', '')
                localStorage.setItem('customerPhoneNumber', '')
                localStorage.setItem('customerName', '')
                return
            }
            state.selectedCustomerName = payload.name ? payload.name : ''
            localStorage.setItem('customerEmail', payload.primaryEmail ? payload.primaryEmail : '')
            localStorage.setItem('customerPhoneNumber', payload.primaryPhoneNumber ? payload.primaryPhoneNumber : '')
            localStorage.setItem('customerName', payload.name ? payload.name : '')

        },
        unsetSelectedPNumber: state => {
            state.selectedPNumber = null
        },
        unsetSelectedPNumberName: state => {
            state.selectedPNumberName = null
        },
        unsetSelectedCvrNumber: state => {
            state.selectedCvrNumber = null
        },
        unsetCustomerCvrAndPNumberDetails: state => {
            state.selectedCvrNumber = null
            state.selectedPNumber = null
            state.selectedPNumberName = null
        }

    },
    getters: {
        getEmailConfirmed: () => {
            return true //state.emailConfirmed
        },
        getSelectedCvrNumber: state => {
            return state.selectedCvrNumber
        },
        getSelectedPNumber: state => {
            return state.selectedPNumber
        },
        getSelectedPNumberName: state => {
            return state.selectedPNumberName
        },
        getSelectedPrivateCustomerName: state => {
            return state.selectedCustomerName
        }
    },
    actions: {
        FetchUserCvrNumber: () => {
            return apiGetUserCvrNumber()
                .then(response => {
                    if (response.status === 200) {

                        return response.data               
                    }
                    return null
                })
        },
        FetchPNumberListByUser: () => {
            return apiGetUserPNumberList()
                .then(response => {
                    if (response.status === 200) {
                        return response.data
                    }

                    return []
                })
        },
        FetchUser: (context, payload) => {
            return apiGetUserDetails(payload.pNumber)
                .then(response => {
                    if (response.status === 200) {

                        context.commit('setEmailConfirmed', {
                            emailConfirmed: response.data.user.emailConfirmed
                        })
                        return response.data               
                    }
                    return null
                })
        },
        FetchUpdateCustomerEanNumberAndEmail: (context, payload) => {
            return apiUpdateCustomerEanNumberAndEmail(payload.pNumber, payload.eanNumber, payload.email)
                .then(response => {
                    if (response.status === 200) {                           
                        return response.data 
                    }
                    return null
                })
        },

        FetcPNumberListByCvrNumber: (context, payload) => {
            return apiGetCvrPNumberList(payload.cvrNumber)
                .then(response => {
                    if (response.status === 200) {
                        return response.data
                    }

                    return []
                })
        },
        LogIn: (context, payload) => {
            return apiLogIn(payload.username, payload.password)
                .then(response => {
                    context.commit('unsetCustomerCvrAndPNumberDetails')
                    if (response.status === 200 && response.data.access_token) {
                    // If the return is positive and we have an access token. we save it in local storage
                        localStorage.setItem('token', response.data.access_token)
                        localStorage.setItem('refreshtoken', response.data.refresh_token)

                        return true
                    }

                    return false
                })
                .catch(() => {
                })  
        },
        LogInCriipto: (context, payload) => {
            return apiLogInCriipto(payload.jwtToken)
                .then(response => {
                    context.commit('unsetCustomerCvrAndPNumberDetails')
                    if (response.status === 200 && response.data.access_token) {
                    // If the return is positive and we have an access token. we save it in local storage
                        localStorage.setItem('token', response.data.access_token)
                        localStorage.setItem('refreshtoken', response.data.refresh_token)

                        return true
                    }

                    return false
                })
                .catch(() => {
                })  
        },
        LogInCriiptoPrivate: (context, payload) => {
            return apiLogInCriiptoPrivate(payload.jwtToken)
                .then(response => {
                    context.commit('unsetCustomerCvrAndPNumberDetails')
                    if (response.status === 200 && response.data.access_token) {
                    // If the return is positive and we have an access token. we save it in local storage
                        localStorage.setItem('token', response.data.access_token)
                        localStorage.setItem('refreshtoken', response.data.refresh_token)

                        return true
                    }
                    return false
                })
                .catch(() => {
                })
        },
        GetCustomerPrivateByCriiptoIdentification: (context, payload) => {
            return apiGetCustomerPrivateByCriiptoIdentification(payload.jwtToken)
                .then(response => {
                    if (response.status === 200) {
                        if(response.data.cutomer && response.data.customer.name)
                            localStorage.setItem('customerName', response.data.customer.name)
                        
                        return response.data
                    }
                    return null
                })
        },
        RefreshTokenInterval: (context, payload) => {
            return apiRefreshToken(payload.refreshToken)
                .then(response => {
                    if (response.status === 200 && response.data.access_token) 
                    {
                        // If the return is positive and we have an access token. we save it in local storage
                        localStorage.setItem('token', response.data.access_token)
                        localStorage.setItem('refreshtoken', response.data.refresh_token)
                        return response
                    }

                    return null
                })
                .catch(() => {

                    /*********************************************************************************************************
                     * IF THE REFRESH FAILS THE PROMISE IS REJECTED
                     * BUT FOR TOKEN HANDLING - THE AXIOS INTERCEPTOR WILL IGNORE IT.
                     * WE MUST THEREFORE TO THE CORRENT HANDLING HERE
                     * WE FORCE THE AXIOS TO IGNORE THE /TOKEN ERRORS BECAUSE WE DONT ALWAYS NEED A REDIRECT WHEN IT FAILS
                     * THE AXIOS INTERCEPTOR WILL ALWAYS ASUME AN ERROR OF ANY KIND OUTSIDE OF 200 SHOULD RESULT IN A REDIRECT
                     **********************************************************************************************************/

                    //We only want to redirect if we are not already at the login page.
                    //This parameter is set to true only for login page
                    if(payload.ignoreRedirect !== true){
                        window.location.href = ''
                    }

                    return null
                })
        },
        IsLogInTokenPresent: () => {
            //TODO: We cant really vlidate a token without calling the backend
            //For new we asume that if a token a present. a valid user is logged in.
            //The moment the token fails to authorize - the user will be redirected to log in
            return Promise.resolve(localStorage.getItem('token') !== null)
        },
        LogOut: context => {
            context.commit('clearIntervalContainer')
            localStorage.clear()
            context.commit('unsetCustomerCvrAndPNumberDetails')
            return true
        },
        CreateUser: (context, payload) => {
            return apiCreateUser(payload.email, payload.cvrNumber, payload.phoneNumber, payload.password)
                .then(response => {
                    if (response.status === 200) {
                        return response.data                                                           
                    }

                    return null
                })
        },
        ChangeUserPassword: (context, payload) => {
            return apiChangeUserPassword(payload.currentPassword, payload.newPassword)
                .then(response => {
                    if (response.status === 200) {
                        return response.data
                    }

                    return null
                })
        },
        RequestPasswordReset: (context, payload) => {
            return apiRequestPasswordReset(payload.email)
                .then(response => {
                    if (response.status === 200) {
                        return response.data
                    }

                    return null
                })
        },
        GetUserEmailConfirmationToken: () => {
            return apiGetUserEmailConfirmationToken()
                .then(response => {
                    if (response.status === 200) {
                        return response.data
                    }

                    return null
                })
        },
        ConfirmPasswordReset: (context, payload) => {
            return apiConfirmPasswordReset(payload.email, payload.newPassword, payload.token)
                .then(response => {
                    if (response.status === 200) {
                        return response.data
                    }

                    return null
                })
        },
        ConfirmEmail: (context, payload) => {
            return apiConfirmEmail(payload.email, payload.token)
                .then(response => {
                    if (response.status === 200) {
                        return response.data
                    }

                    return null
                })
        },
        ValidateCvrNumber: (context, payload) => {
            return apiValidateCvrNumber(payload.cvrNumber)
                .then(response => {
                    if (response.status === 200) {
                        return response.data
                    }

                    return null
                })
        },
        UpdatePrivateUserDetails: (context, payload) => {
            return apiUpdatePrivateUserDetails(payload.email, payload.phoneNumber)
                .then(response => {
                    if (response.status === 200) {
                        localStorage.setItem('customerEmail', payload.email)
                        localStorage.setItem('customerPhoneNumber', payload.phoneNumber)
                        return response.data
                    }

                    return null
                })
        },
        CreateValidateUserEmailCode: (context, payload) => {
            return apiCreateValidateUserEmailCode(payload.jwtToken, payload.email)
                .then(response => {
                    if (response.status === 200) {
                        return response.data
                    }

                    return null
                })
        },
        ValidateUserEmailCode: (context, payload) => {
            return apiValidateUserEmailCode(payload.jwtToken, payload.email, payload.code)
                .then(response => {
                    if (response.status === 200) {
                        return response.data
                    }

                    return null
                })
        },
        FetchOrderPdf: (context, payload) => {
            return apiGetOrderPdf(payload.pNumber, payload.registrationId, payload.orderId)
                .then(response => {
                    let header = response.headers.get('content-disposition')
                    let parts = header ? header.split(';') : null
                    let filename = parts ? parts[1].split('=')[1] : null
                    if (filename) {
                        response.blob()
                            .then(blob => {
                                const url = window.URL.createObjectURL(blob)
                                const a = document.createElement('a')
                                a.href = url
                                // the filename you want
                                a.download = filename
                                a.click()
                                window.URL.revokeObjectURL(url)
                            })
                    }
                })
        },
        FetchCustomerOrders: (context, payload) => {
            return apiGetCustomerOrders(payload.pNumber)
                .then(response => {
                    if (response.status === 200) {
                        return response.data
                    }

                    return null
                })
        }
    }
}

